@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto&display=swap');
body {
	font-family: 'Mulish', sans-serif;
}
.pic {
	max-width: 100%;
	height: auto;
	margin-top: 24px;
}
.hline {
	border: 0.5px black solid;
}
/* style to remove the red underline on links */
a, a:active {
	text-decoration: none;
}

/* adding css style for the techbackground next and previous arrows */

.slick-next:before,
.slick-prev:before {
	color: rgb(134, 81, 81) !important;
}

.slick-prev:before {
	content: url(./images/arrow_back_ios_24px.png) !important;
}

.slick-next:before {
	content: url(./images/arrow_forward_ios_24px.png) !important;
}

/* adding css style for the Blog responsiveness */

@media only screen and (max-width: 1023px) and (min-width: 769px) {
.blog-space {
    padding-left: 2rem  !important;
    padding-right: 2rem  !important;
}
}

@media only screen and (max-width: 767px) {
.blog-space {
    width: 90% !important;
	margin: auto;
}
}
