@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
	font-family: 'Mulish', sans-serif;
}
.pic {
	max-width: 100%;
	height: auto;
	margin-top: 24px;
}
.hline {
	border: 0.5px black solid;
}
/* style to remove the red underline on links */
a, a:active {
	text-decoration: none;
}

/* adding css style for the techbackground next and previous arrows */

.slick-next:before,
.slick-prev:before {
	color: rgb(134, 81, 81) !important;
}

.slick-prev:before {
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAWCAYAAADwza0nAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADLSURBVHgBtdSxCsIwEAbgu7ZCR10UQaFZFDd9hD6KT+gj1DdwFFzMIAhOXdqlJmejIFabNCc0U0L4kuP4EwTPcbrc1kiYadD78l5ukYMIaGjWClSKXERIcjmbiICLdKVTM0cuWomptMIu1Ap90A/0RQ3IQW/IRU94PF+TKIoOHGRGEEOcN0vwChMEQozy+pY6Qvg6gCAJB2FmKnFBa3PqHakq5W7OP7gzADbsFbk27B3yb+zsvQuzH7LBRVVs+vs6PjEB7JBALubj9AEylffwRIVp6AAAAABJRU5ErkJggg==) !important;
}

.slick-next:before {
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAWCAYAAADwza0nAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADRSURBVHgBldS9CoMwEAfwu9iKo10KBQezFAod+gj1jfsGeQWHQsEOCgqCk4uLtU2N2GL9aC7/KRf45UICB9AmSgsRZUV8zbITEIP3ND9LsERflzXWwdHzQh1kq8YJJULS164tbUHpzDjflK/HMzDF+Fnc4txna0ugBJ9ybRwWJhjHG1Q8gVQ8Cyl4EerwX7iEq6biDAgZn+6A4zJdN6vtBoNu7SnBge8SNEV7b7v8ODo0CyloAqnoB5qgLzRFKt13mKIOqtFhijqoRgcCXkyQyhvhSaWSLZ5nRgAAAABJRU5ErkJggg==) !important;
}

/* adding css style for the Blog responsiveness */

@media only screen and (max-width: 1023px) and (min-width: 769px) {
.blog-space {
    padding-left: 2rem  !important;
    padding-right: 2rem  !important;
}
}

@media only screen and (max-width: 767px) {
.blog-space {
    width: 90% !important;
	margin: auto;
}
}

